










import Vue from "vue";
import { Component } from "vue-property-decorator";
import { mapGetters, mapActions } from "vuex";
import Header from "./views/Header.vue";
import Footer from "./views/Footer.vue";
import Issue from "./views/Landing.vue";
import VueQrcode from "vue-qrcode";
import VueRouter from "vue-router";

declare module "vue/types/vue" {
  interface Vue {
    $router: VueRouter;
  }
}

@Component({
  name: "App",
  components: {
    "buybc-issue": Issue,
    "buybc-footer": Footer,
    "buybc-header": Header,
    "vue-qrcode": VueQrcode,
  },
  computed: {
    ...mapGetters("oidcStore", {
      oidcIsAuthenticated: "oidcIsAuthenticated",
    }),
  },
  methods: {
    ...mapActions("oidcStore", {
      authenticateOidcPopup: "authenticateOidcPopup",
      removeOidcUser: "removeOidcUser",
    }),
  },
})
export default class App extends Vue {
  oidcIsAuthenticated!: boolean;
  authenticateOidcPopup!: () => any;
  removeOidcUser!: () => any;

  private userLoaded(e: any): void {
      console.log('I am listening to the user loaded event in vuex-oidc', e.detail);
  }

  private oidcError(e: any): void {
      console.log('oidc Error in vuex-oidc', e.detail);
  }

  private automaticSilentRenewError(e: any): void {
        console.log('silent renew Error in vuex-oidc', e.detail);
  }

  public signOut() {
    this.removeOidcUser().then(() => {
      this.$router.push("/");
    });
  }

  public mounted() {
    window.addEventListener("vuexoidc:userLoaded", this.userLoaded);
    window.addEventListener("vuexoidc:oidcError", this.oidcError);
    window.addEventListener(
      "vuexoidc:automaticSilentRenewError",
      this.automaticSilentRenewError
    );
  }

  public destroyed() {
    window.removeEventListener("vuexoidc:userLoaded", this.userLoaded);
    window.removeEventListener("vuexoidc:oidcError", this.oidcError);
    window.removeEventListener(
      "vuexoidc:automaticSilentRenewError",
      this.automaticSilentRenewError
    );
  }
}
