<template>
  <div>
    <h1>Error in authentication</h1>
  </div>
</template>

<script>
export default {
  name: "OidcCallbackError"
};
</script>
