import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Landing from "../views/Landing.vue";
import OidcCallback from "@/views/OidcCallback.vue";
import OidcPopupCallback from "@/views/OidcPopupCallback.vue";
import OidcCallbackError from "@/views/OidcCallbackError.vue";
import { vuexOidcCreateRouterMiddleware } from "vuex-oidc";
import store from "@/store/index";
import Issue from "../views/Issue.vue";
import Login from "../views/Login.vue";
import PageNotFound from "../views/PageNotFound.vue";
import { AppConfig } from "@/models/appConfig";
import * as ConfigService from "@/services/config";
import isAuthenticated from "./guards/isAuthenticated";
import AppStore from "@/store/index";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Landing",
    component: Landing,
    meta: {
      isPublic: false,
    },
    beforeEnter: isAuthenticated,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      isPublic: true,
    },
  },
  {
    path: "/issue",
    name: "Issue",
    component: Issue,
    meta: {
      isPublic: false,
    },
    beforeEnter: isAuthenticated,
  },
  {
    path: "/oidc-callback", // Needs to match redirectUri in your oidcSettings
    name: "oidcCallback",
    component: OidcCallback,
  },
  {
    path: "/oidc-popup-callback", // Needs to match popupRedirectUri in your oidcSettings
    name: "oidcPopupCallback",
    component: OidcPopupCallback,
  },
  {
    path: "/oidc-callback-error", // Needs to match redirect_uri in your oidcSettings
    name: "oidcCallbackError",
    component: OidcCallbackError,
    meta: {
      isPublic: true,
    },
  },
  {
    path: "*",
    name: "NotFound",
    component: PageNotFound,
    meta: {
      isPublic: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
