


















import { Component, Vue } from "vue-property-decorator";
import router from "vue-router";
@Component
export default class PageNotFound extends Vue {
  private path = "";
  private mounted() {
    this.path = this.$route.fullPath;
  }
}
