import { OrganizationState, RootState } from "@/models/storeState";
import { ActionTree, ActionContext } from "vuex";
import { Organization } from "@/models/organization";

export const actions: ActionTree<OrganizationState, RootState> = {
  async getOrganization(
    context: ActionContext<OrganizationState, RootState>
  ): Promise<Organization> {
    return new Promise<Organization>((resolve, reject) => {
      return resolve(
        context.rootGetters["organization/getOrganization"] as Organization
      );
    });
  },
};
