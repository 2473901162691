import { Organization } from "@/models/organization";
import { OrganizationState, StateType } from "@/models/storeState";
import Vue from "vue";
import { MutationTree } from "vuex";

export const mutations: MutationTree<OrganizationState> = {
  setOrganization(state: OrganizationState, organization: Organization) {
    Vue.set(state.organization, "organization", organization);
    state.error = false;
    state.statusMessage = "success";
    state.stateType = StateType.INITIALIZED;
  },
  organizationError(state: OrganizationState, errorMessage: string) {
    state.error = true;
    state.statusMessage = errorMessage;
    state.stateType = StateType.ERROR;
  },
};
