import Vue from "vue";
import Vuex, { Store, StoreOptions } from "vuex";
import { vuexOidcCreateStoreModule } from "vuex-oidc";
import { AppConfig } from "@/models/appConfig";
import { RootState } from "@/models/storeState";
import { configuration } from "@/store/modules/configuration/configuration";
import { organization } from "@/store/modules/organization/organization"

class AppStore extends Vuex.Store<RootState> {
  private static instance: Store<RootState>;
  private constructor(config: AppConfig) {
    Vue.use(Vuex);
    const storeOptions: StoreOptions<RootState> = {
      state: {
        version: "1.0.0", // a simple property
      },
      modules: {
        configuration,
        organization,
        oidcStore: vuexOidcCreateStoreModule(
          config.authentication.oidcSettings,
          {
            namespaced: true,
            dispatchEventsOnWindow: true,
          },
          {
            userLoaded: (user) => console.log("OIDC user is loaded:", user),
            userUnloaded: () => console.log("OIDC user is unloaded"),
            accessTokenExpiring: () => console.log("Access token will expire"),
            accessTokenExpired: () => console.log("Access token did expire"),
            silentRenewError: () => console.log("OIDC user is unloaded"),
            userSignedOut: () => console.log("OIDC user is signed out"),
            oidcError: (payload) => console.log("OIDC error", payload),
            automaticSilentRenewError: (payload) => console.log("Automatic silent renew failed.", payload),
          }
        ),
      },
    };

    super(storeOptions);
  }

  static getInstance(config?: AppConfig): AppStore {
    if (!AppStore.instance) {
      if (!config) {
        throw new Error(
          "No AppConfig was provided and no Store instance is available"
        );
      }
      AppStore.instance = new AppStore(config);
    }

    return AppStore.instance;
  }
}

export default AppStore;