














import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class LoadingComponent extends Vue {
  @Prop() isLoading!: boolean;
}
