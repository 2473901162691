import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Vuetify from "vuetify";
import vuetify from "@/plugins/vuetify";
import "vuetify/dist/vuetify.min.css";
import axios from "axios";
import VueContentPlaceholders from "vue-content-placeholders";
import store from "./store/index";
import VueSocialSharing from "vue-social-sharing";
import { AppConfig } from "./models/appConfig";
import * as ConfigService from "@/services/config";
import AppStore from "./store/index";

Vue.prototype.$http = axios;

Vue.use(VueSocialSharing);
Vue.use(Vuetify);
Vue.use(VueContentPlaceholders);

Vue.config.productionTip = false;

Promise.all([ConfigService.getAppConfig()]).then((values) => {
  const config = values[0] as AppConfig;
  new Vue({
    vuetify,
    router: router,
    store: AppStore.getInstance(config),
    created: function() {
      this.$store.commit("configuration/setAppConfig", config);
    },
    render: (h) => h(App),
  }).$mount("#app");
});
