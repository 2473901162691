var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"700"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('LoadingComponent',{attrs:{"is-loading":_vm.isLoading}}),_c('v-card',[_c('v-card-title',[_c('v-img',{attrs:{"src":require("../assets/buyBC_Logo_Horiz_RGB-300x119.png"),"contain":"","height":"100","max-height":"100"}})],1),(_vm.selectedStatus === 'Active')?_c('v-card-title',{staticClass:"headline"},[_vm._v(" Issue Buy BC Credential ")]):_c('v-card-title',{staticClass:"headline"},[_vm._v(" Revoke Buy BC Credential ")]),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-row',{staticClass:"mt-2"},[_c('v-col',[_c('v-text-field',{attrs:{"label":"Business Name","rules":[_vm.rules.required],"outlined":"","readonly":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Registration ID","rules":[_vm.rules.required],"outlined":"","readonly":""},model:{value:(_vm.id),callback:function ($$v) {_vm.id=$$v},expression:"id"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"License Number","rules":[_vm.rules.required],"outlined":"","disabled":_vm.selectedStatus == 'Inactive'},model:{value:(_vm.licenseNumber),callback:function ($$v) {_vm.licenseNumber=$$v},expression:"licenseNumber"}})],1),_c('v-col',[_c('v-select',{attrs:{"outlined":"","items":_vm.licenseType,"rules":[_vm.rules.required],"label":"License Type","disabled":_vm.selectedStatus == 'Inactive'},model:{value:(_vm.selectedLicenseType),callback:function ($$v) {_vm.selectedLicenseType=$$v},expression:"selectedLicenseType"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"outlined":"","items":_vm.selectedStatus === 'Active'
                  ? _vm.activeStatusReason
                  : _vm.inactiveStatusReason,"rules":[_vm.rules.required],"disabled":_vm.selectedStatus === '',"label":"Status Reason"},model:{value:(_vm.selectedStatusReason),callback:function ($$v) {_vm.selectedStatusReason=$$v},expression:"selectedStatusReason"}})],1),_c('v-col',[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.licenseEffectiveDate,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.licenseEffectiveDate=$event},"update:return-value":function($event){_vm.licenseEffectiveDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.selectedStatus == 'Active'
                      ? 'License Effective Date'
                      : 'License Revocation Date',"rules":[_vm.rules.required],"outlined":"","readonly":""},model:{value:(_vm.licenseEffectiveDate),callback:function ($$v) {_vm.licenseEffectiveDate=$$v},expression:"licenseEffectiveDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.licenseEffectiveDate),callback:function ($$v) {_vm.licenseEffectiveDate=$$v},expression:"licenseEffectiveDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.saveDate(_vm.licenseEffectiveDate)}}},[_vm._v(" OK ")])],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasErrors),expression:"hasErrors"}],attrs:{"prominent":"","type":"error"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"grow"},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])],1)],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),(_vm.selectedStatus === 'Active')?_c('v-btn',{staticClass:"button mb-2 primary",on:{"click":function($event){return _vm.getOtherCredentialDetails(true)}}},[_vm._v("Issue")]):_c('v-btn',{staticClass:"button mb-2 error",on:{"click":function($event){return _vm.getOtherCredentialDetails(false)}}},[_vm._v("Revoke")]),_c('v-btn',{staticClass:"button mb-2",on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Cancel")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }