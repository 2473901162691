import { Organization } from "@/models/organization";
import { OrganizationState, RootState } from "@/models/storeState";
import { GetterTree } from "vuex";

export const getters: GetterTree<OrganizationState, RootState> = {
  getOrganization(state: OrganizationState): Organization {
    const { organization } = state;
    return organization;
  }
};
