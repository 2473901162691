






















































































































































































































































































































/* eslint-disable */
import { Component, Vue, Watch } from "vue-property-decorator";
import axios from "axios";
import LoadingComponent from "../components/Loading.vue";
import CredentialDetailsModal from "../components/CredentialDetailsModal.vue";
import IssueCredentialModal from "../components/IssueCredentialModal.vue";
import VueQrcode from "vue-qrcode";
import domtoimage from "dom-to-image";
import { saveAs } from "file-saver";
import { mapActions, mapGetters } from "vuex";
import jsPDF from "jspdf";
import store from "../store/index";
import { DateTime } from "luxon";

@Component({
  components: {
    LoadingComponent: LoadingComponent,
    CredentialDetailsModal: CredentialDetailsModal,
    IssueCredentialModal: IssueCredentialModal,
    VueQrcode: VueQrcode,
  },
  methods: mapActions({
    signOut: "oidcStore/signOutOidc",
    signIn: "oidcStore/authenticateOidc",
  }),
  computed: mapGetters({ isAuthenticated: "oidcStore/oidcIsAuthenticated" }),
})
export default class Issue extends Vue {
  private config = this.$store.getters["configuration/getConfiguration"];
  private isLoading = false;
  private hasIssuedCredential = false;
  private issueCredentialFailed = false;
  private isDetailsModalVisible = false;
  private isIssueModalVisible = false;
  private credentials: any[] = [];
  private businessNumber: string = "";
  private isMobile = false;
  private verified = false;
  private issueCredentialDetails: {} = {};
  private successText: string = "";
  private errorText: string = "";
  private searchResultTableHeaders: any[] = [
    {
      text: "Business Name",
      value: "names[0].text",
      sortable: false,
    },
    {
      text: "Business Number",
      value: "source_id",
      sortable: false,
    },
  ];
  private selectedOrgData: {
    id: number;
    businessNumber: string;
    attributes: any[];
    name: string;
    credentialId: string;
    registrationId: string;
    active: boolean;
    revoked: boolean;
  } = {
    id: -1,
    businessNumber: "",
    attributes: [],
    name: "",
    credentialId: "",
    registrationId: "",
    active: true,
    revoked: false,
  };
  private selectedCredential: {} = {};
  private orgTableHeaders: any[] = [];
  private orgTableData: any[] = [];
  private credTableHeaders: any[] = [];
  private credTableData: any[] = [];

  private orgTableLoaded = false;
  private credTableLoaded = false;

  private mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
    this.selectedOrgData =
      this.$store.getters["organization/getOrganization"].organization;
    this.loadOrgTable();
  }

  private openOrgBook() {
    window.open(
      this.config.app.api.orgbookOrgUrl + this.selectedOrgData.registrationId,
      "_blank"
    );
  }

  private getColor(status: string) {
    if (status === "Active") {
      return "#4CAF50";
    } else if (status === "Inactive") {
      return "#F44336";
    } else if (status.toString() === "true") {
      return "#2196F3";
    } else if (status.toString() === "false") {
      return "#9E9E9E";
    }
  }

  private loadOrgTable() {
    this.isLoading = true;
    this.orgTableData.push({
      text: "Organization",
      value: this.selectedOrgData.name,
    });
    this.orgTableData.push({
      text: "Registration ID",
      value: this.selectedOrgData.registrationId,
    });
    this.selectedOrgData.attributes.forEach((item) => {
      this.orgTableData.push(item);
    });
    this.orgTableHeaders.push({
      text: "Attribute",
      value: "text",
      sortable: false,
    });
    this.orgTableHeaders.push({
      text: "Value",
      value: "value",
      sortable: false,
    });
    this.orgTableLoaded = true;
    this.getCredentials();
  }

  private close(value: boolean) {
    this.hasIssuedCredential = value;
    this.issueCredentialFailed = value;
  }

  private async getCredentials() {
    await axios({
      method: "GET",
      url:
        this.config.app.api.orgbookAPIUrl +
        "/topic/" +
        this.selectedOrgData.id +
        "/credentialset",
    }).then((res) => {
      this.credentials = res.data;
    });
    this.loadCredTable();
  }

  private async loadCredTable() {
    this.credTableData = [];
    let hasActive = false;
    let activeCredId = "";
    this.credTableHeaders = [
      {
        text: "Date Created",
        value: "issueDate",
        sortable: true,
      },
      {
        text: "Issuer",
        value: "issuer",
        sortable: false,
      },
      {
        text: "Buy BC License Status",
        value: "licenseStatus",
        sortable: false,
      },
      {
        text: "Buy BC License Status Reason",
        value: "licenseStatusReason",
        sortable: false,
      },
      {
        text: "Buy BC License Number",
        value: "licenseNumber",
        sortable: true,
      },
      {
        text: "Date Effective",
        value: "effectiveDate",
        sortable: true,
      },
      {
        text: "Details",
        value: "details",
        sortable: false,
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
      },
    ];
    for (let z = 0; z < this.credentials.length; z++) {
      await axios({
        method: "GET",
        url:
          this.config.app.api.orgbookAPIUrl +
          "/v3/credentialtype/" +
          this.credentials[z].credentials[0].credential_type.id,
      }).then(async (res: any) => {
        var licenseStatus = "";
        var licenseStatusReason = "";
        var licenseNumber = "";
        var issueDate = "";
        if (
          this.credentials[z].credentials[0].credential_type.description ===
          "business_number.registries.ca"
        ) {
          this.selectedOrgData.businessNumber =
            this.credentials[z].credentials[0].local_name.text;
          if (
            this.orgTableData[this.orgTableData.length - 1].text !=
            "Business Number"
          ) {
            this.orgTableData.push({
              text: "Business Number",
              value: this.selectedOrgData.businessNumber,
            });
          }
        }
        if (
          this.credentials[z].credentials[0].credential_type.description ===
          this.config.app.issuer.schemaName
        ) {
          // Buy BC License, get active attributes
          for (let k = 0; k < this.credentials[z].credentials.length; k++) {
            if (this.credentials[z].credentials[k].revoked === true) {
              // Credential has been revoked
              await axios({
                method: "GET",
                url:
                  this.config.app.api.orgbookAPIUrl +
                  "/v3/credential/" +
                  this.credentials[z].credentials[k].id,
              }).then(async (res: any) => {
                licenseStatus = res.data.attributes[2].value;
                licenseStatusReason = res.data.attributes[4].value;
                licenseNumber = res.data.attributes[0].value;
                issueDate = res.data.attributes[6].value;
              });
              this.credTableData.push({
                issuer: res.data.issuer.name,
                effectiveDate: this.formatDate(
                  DateTime.fromISO(this.credentials[z].first_effective_date)
                    .setZone("America/Vancouver")
                    .toISO()
                ),
                licenseStatus: licenseStatus,
                credentialRevoked: true,
                issueDate: this.formatDate(issueDate), // TODO: FIX THIS
                licenseNumber: licenseNumber,
                licenseStatusReason: licenseStatusReason,
                data: this.credentials[z],
              });
            } else {
              // Credential is active (latest)
              await axios({
                method: "GET",
                url:
                  this.config.app.api.orgbookAPIUrl +
                  "/v3/credential/" +
                  this.credentials[z].credentials[k].id,
              }).then((res: any) => {
                licenseStatus = res.data.attributes[2].value;
                licenseStatusReason = res.data.attributes[4].value;
                licenseNumber = res.data.attributes[0].value;
                issueDate = res.data.attributes[6].value;
                licenseStatus = res.data.attributes[2].value;
                if (licenseStatus == "Active") {
                  hasActive = true;
                  activeCredId = res.data.credential_id;
                }
              });
              this.credTableData.push({
                issuer: res.data.issuer.name,
                effectiveDate: this.formatDate(
                  DateTime.fromISO(this.credentials[z].first_effective_date)
                    .setZone("America/Vancouver")
                    .toISO()
                ),
                licenseStatus: licenseStatus,
                credentialRevoked: false,
                issueDate: this.formatDate(issueDate), // TODO: FIX THIS
                licenseNumber: licenseNumber,
                licenseStatusReason: licenseStatusReason,
                data: this.credentials[z],
              });
            }
          }
        }
      });
    }
    if (hasActive) {
      await axios({
        url:
          this.config.app.api.orgbookAPIUrl +
          "/v3/credential/" +
          activeCredId +
          "/verify",
      }).then((res: any) => {
        this.verified = true;
        this.isLoading = false;
        this.credTableLoaded = true;
      });
    } else {
      this.verified = false;
      this.isLoading = false;
      this.credTableLoaded = true;
    }
  }

  private viewDetailModal(credential: any) {
    this.selectedCredential = credential;
    this.isDetailsModalVisible = true;
  }

  private viewIssueModal() {
    this.isIssueModalVisible = true;
  }

  private toggleCredModal() {
    this.isDetailsModalVisible = !this.isDetailsModalVisible;
  }

  private toggleIssueModal() {
    this.isIssueModalVisible = !this.isIssueModalVisible;
  }

  private backButtonClicked() {
    this.$router.push("/");
  }

  private downloadQrPng() {
    let name = this.selectedOrgData.name;
    let regId = this.selectedOrgData.registrationId;
    domtoimage
      .toBlob(document.getElementById("qrcode"))
      .then(function (blob: any) {
        saveAs(blob, regId + ".png");
      });
  }

  private downloadQrSvg() {
    let name = this.selectedOrgData.name;
    let regId = this.selectedOrgData.registrationId;
    domtoimage
      .toSvg(document.getElementById("qrcode"))
      .then(function (blob: any) {
        saveAs(blob, regId + ".svg");
      });
  }

  private generatePdf() {
    let pdf = new jsPDF();
    let name = this.selectedOrgData.name;
    let regId = this.selectedOrgData.registrationId;
    domtoimage
      .toPng(document.getElementById("qrcode"))
      .then(function (blob: any) {
        pdf.addImage(
          require("../assets/buyBC_Logo_Horiz_RGB-300x119.png"),
          10,
          10,
          50,
          20
        );
        pdf.setFont("helvetica");
        pdf.setFontSize(18);
        pdf.text(name, 10, 40);
        pdf.setFontSize(12);
        pdf.text("Registration ID: " + regId, 10, 45);
        pdf.addImage(blob, "png", 5, 55, 60, 60);
        pdf.save(regId + ".pdf");
      });
  }

  private revokeCredential(details: any) {
    this.isLoading = true;
    axios({
      url:
        this.config.app.api.orgbookAPIUrl +
        "/credential/" +
        details.data.latest_credential_id +
        "/formatted",
    }).then((res: any) => {
      this.issueCredentialDetails = {
        licenseEffectiveDate: new Date(res.data.attributes[5].value)
          .toISOString()
          .substr(0, 10),
        licenseNumber: res.data.attributes[0].value,
        licenseType: res.data.attributes[1].value,
        status: "Inactive",
        statusReason: "",
        attributes: res.data.attributes,
      };
      this.viewIssueModal();
      this.isLoading = false;
    });
  }

  private onIssueSuccess(action: string) {
    if (action === "ISSUE") {
      this.successText = "Buy BC Credential Issued!";
    } else {
      this.successText = "Buy BC Credential Revoked!";
    }
    this.toggleIssueModal();
    this.isLoading = true;
    this.hasIssuedCredential = true;
    this.getCredentials();
  }

  // BEN TODO
  private onIssueFail(message: any) {
    this.toggleIssueModal();
    this.issueCredentialFailed = true;
    this.errorText = message;
  }

  private formatDate(date: any) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear(),
      hour = d.getHours().toString(),
      minute = d.getMinutes().toString(),
      seconds = d.getSeconds().toString();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    if (hour.length < 2) hour = "0" + hour;
    if (minute.length < 2) minute = "0" + minute;
    if (seconds.length < 2) seconds = "0" + seconds;

    return (
      [year, month, day].join("-") + " " + [hour, minute, seconds].join(":")
    );
  }

  private formatAttribute(str: string) {
    let frags = str.split("_");
    for (let i = 0; i < frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(" ");
  }

  private beforeDestroy() {
    if (typeof window === "undefined") return;
    window.removeEventListener("resize", this.onResize);
  }

  private onResize() {
    this.isMobile = window.innerWidth < 600;
  }
}
