




























































































































































/* eslint-disable */
import { Component, Vue, Prop, Watch, Emit } from "vue-property-decorator";
import axios from "axios";
import LoadingComponent from "./Loading.vue";
import { DateTime } from "luxon";

@Component({
  components: {
    LoadingComponent,
  },
})
export default class IssueCredentialModal extends Vue {
  private config = this.$store.getters["configuration/getConfiguration"];
  @Prop() isVisible!: boolean;
  @Prop() entityName!: string;
  @Prop() registrationId!: string;
  @Prop() details!: {
    licenseEffectiveDate: string;
    licenseNumber: string;
    status: string;
    statusReason: string;
    licenseType: string;
    attributes: any[];
  };
  @Prop() allCredentials!: any[];

  private idToken: string = "";
  private menu: boolean = false;
  private dialog: boolean = false;
  private action: string = "ISSUE";

  private isLoading: boolean = false;
  private attributes: any[] = [];
  private credentials: any[] = [];
  private name: string = "";
  private id: string = "";
  private licenseNumber: string = "";
  private licenseEffectiveDate: string = "";
  private licenseType: string[] = ["Certification", "Marketing"];
  private selectedLicenseType: string = "";

  private selectedStatus: string = "Active";
  private status: string[] = ["Active", "Inactive"];

  private selectedStatusReason: string = "License Approved";
  private activeStatusReason: string[] = ["License Approved"];
  private inactiveStatusReason: string[] = [
    "License Expired",
    "License Revoked",
  ];

  private hasErrors = false;
  private errorMessage: string = "";

  private rules = {
    required: (value: string) => !!value || "Field required.",
  };

  @Watch("isVisible")
  private onVisibilityChanged() {
    this.dialog = this.isVisible;
    this.credentials = this.allCredentials;
  }

  @Watch("entityName")
  private onNamePropChanged() {
    this.name = this.entityName;
  }

  @Watch("isVisible")
  private onIdPropChanged() {
    this.id = this.registrationId;
  }

  @Watch("details")
  private onDetailsChanged() {
    this.licenseNumber = this.details.licenseNumber;
    this.selectedLicenseType = this.details.licenseType;
    this.selectedStatus = this.details.status;
    this.selectedStatusReason = this.details.statusReason;
    this.attributes = this.details.attributes;
    this.credentials = this.allCredentials;
  }

  @Watch("dialog")
  private onDialogChange(val: boolean) {
    val || this.close();
  }

  private mounted() {
    this.idToken = this.$store.getters["oidcStore/oidcIdToken"];
    this.licenseEffectiveDate = new Date().toISOString().substr(0, 10);
  }

  private saveDate(date: any) {
    (this.$refs.menu as Vue & { save: (date: any) => void }).save(date);
  }

  private async getOtherCredentialDetails(issue: boolean) {
    issue ? (this.action = "ISSUE") : (this.action = "REVOKE");
    this.isLoading = true;
    var hasActiveCredential = false;
    var duplicateLicenseNumber = false;
    var licenseNumberExists = false;

    for (let i = 0; i < this.credentials.length; i++) {
      for (let k = 0; k < this.credentials[i].credentials.length; k++) {
        // Search credentials belonging to this holder
        if (
          this.credentials[i].credentials[k].credential_type.description ===
          this.config.app.issuer.schemaName
        ) {
          // Credential comes from Buy BC
          await axios({
            method: "GET",
            url:
              this.config.app.api.orgbookAPIUrl +
              "/v3/credential/" +
              this.credentials[i].credentials[k].credential_id +
              "/latest",
          }).then((res: any) => {
            // Get attributes of Buy BC credential to check if it's active
            if (res.data.attributes[2].value === "Active") {
              // Found an active Buy BC credential for this holder
              hasActiveCredential = true;
            }
            if (res.data.attributes[0].value === this.licenseNumber) {
              duplicateLicenseNumber = true;
              if (res.data.attributes[2].value === "Active") {
                licenseNumberExists = true;
              }
            }
          });
        }
      }
    }
    // Once we have analyzed other credentials, call issue credential
    if (issue) {
      this.issueCredential(hasActiveCredential, duplicateLicenseNumber);
    } else {
      this.revokeCredential(licenseNumberExists);
    }
  }

  private issueCredential(
    hasActiveCredential: boolean,
    duplicateLicenseNumber: boolean
  ) {
    /* Business Logic:
      - Check if VC does not already exist or has existed in the past for the current holder with the SAME LICENSE NUMBER before creating VC
      - A holder can only have one valid Buy BC VC at a time, may have more than one revoked or expired VCs
      - If a credential has been expired or revoked it can NOT be reactivated. IAF will need to create a new license and new license number under a new Buy BC agreement
    */
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      // Business Logic
      if (hasActiveCredential) {
        this.isLoading = false;
        this.hasErrors = true;
        this.errorMessage =
          "Cannot issue Buy BC license. An active Buy BC license already exists for " +
          this.name +
          ". If you would like to issue a new Buy BC license, revoke the current license first.";
      } else if (duplicateLicenseNumber) {
        this.isLoading = false;
        this.hasErrors = true;
        this.errorMessage =
          "Cannot issue Buy BC license. A Buy BC license with license number " +
          this.licenseNumber +
          " has already been created. Please enter a new license number to create a new Buy BC license.";
      } else {
        /* license does not exist and license number has not been created in the past*/
        this.sendPayload();
      }
    } else {
      this.isLoading = false;
    }
  }

  private async revokeCredential(licenseNumberExists: boolean) {
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      if (!licenseNumberExists) {
        // License with license number does not exist
        this.isLoading = false;
        this.hasErrors = true;
        this.errorMessage =
          "Cannot revoke Buy BC license. No active Buy BC license with license number " +
          this.licenseNumber +
          " exists for " +
          this.name +
          ".";
      } else if (
        DateTime.fromISO(this.licenseEffectiveDate) <
        DateTime.fromISO(this.details.licenseEffectiveDate)
      ) {
        // License revocation date before original license effective date
        this.hasErrors = true;
        this.isLoading = false;
        this.errorMessage =
          "Revocation date cannot be before license's original issue date.";
      } else {
        this.sendPayload();
      }
    } else {
      this.isLoading = false;
    }
  }

  private sendPayload() {
    axios({
      method: "POST",
      url: this.config.app.api.buyBCIssueUrl,
      data: [
        {
          attributes: {
            entity_name: this.name,
            corp_num: this.registrationId,
            license_number: this.licenseNumber,
            license_type: this.selectedLicenseType,
            status: this.selectedStatus,
            status_reason: this.selectedStatusReason,
            license_effective_date: DateTime.fromISO(this.licenseEffectiveDate)
              .setZone("America/Vancouver")
              .toISO(),
            issue_date: new Date().toISOString(),
            effective_date: new Date().toISOString(),
            expiry_date: "",
          },
          schema: "license.buybc.gov.bc.ca",
          version: "0.1.0",
        },
      ],
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${this.idToken}`,
      },
    })
      .then((res) => {
        this.isLoading = false;
        this.close();
        if (res.data.length > 0) {
          if (res.data[0].success == false) {
            this.emitFailure(res.data[0].result);
          } else {
            this.emitSuccess();
          }
        } else {
          this.emitSuccess();
        }
      })
      .catch((err: any) => {
        this.isLoading = false;
        this.close();
        this.emitFailure(err);
      });
  }

  private close() {
    this.dialog = false;
    this.hasErrors = false;
    this.errorMessage = "";
    this.licenseNumber = "";
    this.selectedStatus = "Active";
    this.selectedStatusReason = "License Approved";
    this.licenseEffectiveDate = new Date().toISOString().substr(0, 10);
    this.selectedLicenseType = "";
    this.emitClose();
  }

  @Emit()
  private emitClose() {
    return;
  }

  @Emit()
  private emitSuccess() {
    return this.action;
  }

  @Emit()
  private emitFailure(message: any) {
    return message;
  }
}
