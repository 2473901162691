



import { Component, Vue } from "vue-property-decorator";
import LoadingComponent from "../components/Loading.vue";
import { mapActions, mapGetters } from "vuex";

@Component({
  components: {
    LoadingComponent,
  },
  methods: mapActions({
    signOut: "oidcStore/signOutOidc",
    signIn: "oidcStore/authenticateOidc",
  }),
  computed: mapGetters({ isAuthenticated: "oidcStore/oidcIsAuthenticated" }),
})
export default class Login extends Vue {
  private isAuthenticated!: boolean;
  private signOut!: () => any;
  private signIn!: () => any;

  private mounted() {
    if (!this.isAuthenticated) {
      this.signIn();
    }
  }
}
