import IssuerStore from "@/store";
import { Route } from "vue-router";
import * as ConfigService from "@/services/config";
import { AppConfig } from "@/models/appConfig";

export default async function isAuthenticated(
  to: Route,
  from: Route,
  next: Function
) {
  const config: AppConfig = await ConfigService.getAppConfig();
  const isAuth = IssuerStore.getInstance(config).getters[
    "oidcStore/oidcIsAuthenticated"
  ];
  if (isAuth) {
    next();
  } else {
    next("/login");
  }
}
