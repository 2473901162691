






















































































/* eslint-disable */
import { Component, Vue, Watch } from "vue-property-decorator";
import axios from "axios";
import LoadingComponent from "../components/Loading.vue";
import VueQrcode from "vue-qrcode";
import router from "vue-router";
import store from "../store";

@Component({
  components: {
    LoadingComponent: LoadingComponent,
    VueQrcode: VueQrcode,
  },
})
export default class Landing extends Vue {
  private config = this.$store.getters["configuration/getConfiguration"];
  private isLoading = false;
  private isAuthenticated = false;
  private searchResults: any[] = [];
  private searchText = "";
  private hasSearched = false;
  private numBusinessesFound = 0;
  private page = 1;
  private isMobile = false;
  private firstIndex = 1;
  private lastIndex = 20;
  private nextPage = "";
  private prevPage = "";
  private selectedOrg: {
    attributes: any[];
    names: any[];
    source_id: string;
    id: number;
    credential_set: { id: number };
    inactive: boolean;
    revoked: boolean;
  } = {
    attributes: [],
    names: [],
    source_id: "",
    credential_set: { id: -1 },
    id: -1,
    inactive: true,
    revoked: false,
  };
  private searchResultTableHeaders: any[] = [
    {
      text: "Business Name",
      value: "names[0].text",
      sortable: false,
    },
    {
      text: "Business Number",
      value: "source_id",
      sortable: false,
    },
  ];
  private selectedOrgData: {
    id: number;
    attributes: any[];
    name: string;
    credentialId: string;
    registrationId: string;
    active: boolean;
    revoked: boolean;
  } = {
    id: -1,
    attributes: [],
    name: "",
    credentialId: "",
    registrationId: "",
    active: true,
    revoked: false,
  };

  private mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
    if (this.$store.getters["oidcStore/oidcIsAuthenticated"]) {
      this.isAuthenticated = true;
    }
  }

  private handleClick(row: any) {
    this.selectedOrg = row;
  }

  private getBusinessResults(searchText: string) {
    this.isLoading = true;
    this.hasSearched = false;
    axios({
      method: "GET",
      url:
        this.config.app.api.orgbookAPIUrl +
        "/v4/search/topic/facets?q=" +
        searchText +
        "&page_size=10&page=1",
    }).then((res: any) => {
      this.searchResults = res.data.objects.results;
      this.numBusinessesFound = res.data.objects.total;
      this.page = res.data.objects.page;
      this.firstIndex = res.data.objects.first_index;
      this.lastIndex = res.data.objects.last_index;
      this.isLoading = false;
      this.hasSearched = true;
    });
  }

  @Watch("page")
  private onPageChanged(page: any) {
    this.isLoading = true;
    axios({
      method: "GET",
      url:
        this.config.app.api.orgbookAPIUrl +
        "/v4/search/topic/facets?q=" +
        this.searchText +
        "&page_size=10&page=" +
        this.page,
    }).then((res: any) => {
      this.page = res.data.objects.page;
      this.searchResults = res.data.objects.results;
      this.firstIndex = res.data.objects.first_index;
      this.hasSearched = true;
      this.lastIndex = res.data.objects.last_index;
      this.isLoading = false;
    });
  }

  @Watch("selectedOrg")
  private orgSelected() {
    this.isLoading = true;
    this.searchText = "";
    this.selectedOrgData = {
      id: -1,
      attributes: [],
      name: "",
      credentialId: "",
      registrationId: "",
      active: true,
      revoked: false,
    };
    this.selectedOrg.attributes.forEach((attribute) => {
      if (
        this.formatAttribute(attribute.type) == "Registration Date" ||
        this.formatAttribute(attribute.type) == "Entity Status" ||
        this.formatAttribute(attribute.type) == "Entity Type"
      ) {
        this.selectedOrgData.attributes.push({
          text: this.formatAttribute(attribute.type),
          value: attribute.value,
        });
      }
    });
    this.selectedOrgData.id = this.selectedOrg.id;
    this.selectedOrgData.name = this.selectedOrg.names[0].text;
    this.selectedOrgData.registrationId = this.selectedOrg.source_id;
    this.$store.commit("organization/setOrganization", this.selectedOrgData);
    this.$router.push("/issue");
  }

  private formatAttribute(str: string) {
    let frags = str.split("_");
    for (let i = 0; i < frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(" ");
  }

  private beforeDestroy() {
    if (typeof window === "undefined") return;
    window.removeEventListener("resize", this.onResize);
  }

  private onResize() {
    this.isMobile = window.innerWidth < 600;
  }
}
