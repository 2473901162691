






































import store from "../store";
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action } from "vuex-class";
import { mapActions, mapGetters } from "vuex";
import router from "vue-router";

@Component({
  methods: mapActions({
    signOut: "oidcStore/signOutOidc",
    signIn: "oidcStore/authenticateOidc",
  }),
  computed: mapGetters({ isAuthenticated: "oidcStore/oidcIsAuthenticated" }),
})
export default class Header extends Vue {
  private config = this.$store.getters["configuration/getConfiguration"];
  private isMobile = false;
  private pageTitle = "Buy BC";
  private isAuthenticated!: boolean;
  private signOut!: () => any;

  private mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
    if (this.config.app.env == "dev") {
      this.pageTitle = "Buy BC - DEV";
    } else if (this.config.app.env == "test") {
      this.pageTitle = "Buy BC - TEST";
    } else {
      this.pageTitle = "Buy BC";
    }
  }
  private beforeDestroy() {
    if (typeof window === "undefined") return;
    window.removeEventListener("resize", this.onResize);
  }

  private onResize() {
    this.isMobile = window.innerWidth < 600;
  }
}
