export interface SelectedOrganization {
  active: boolean;
  attributes: [any];
  credentialId: string;
  id: number;
  name: string;
  registrationId: string;
  revoked: boolean;
}

export class Organization {
  public organization!: SelectedOrganization;
}
