




























import { Component, Vue } from "vue-property-decorator";
import { mapActions } from "vuex";

@Component({
  methods: {
    ...mapActions("oidcStore", ["oidcSignInCallback"])
  }
})
export default class OidcCallback extends Vue {
  mounted() {
    this.$store
      .dispatch("oidcStore/oidcSignInCallback")
      .then(redirectPath => {
        this.$router.push(redirectPath);
      })
      .catch(err => {
        console.error(err);
        this.$router.push({ path: "/oidc-callback-error" }); // Handle errors any way you want
      });
  }
}
