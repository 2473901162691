import { Organization } from "@/models/organization";
import { OrganizationState, RootState, StateType } from "@/models/storeState";
import { Module } from "vuex";
import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";

export const state: OrganizationState = {
  statusMessage: "",
  organization: new Organization(),
  error: false,
  stateType: StateType.NONE
};

const namespaced = true;

export const organization: Module<OrganizationState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
