export interface AppConfig {
    env: string;
    issuer: {
      name: string;
      schemaName: string;
    };
    inviteRequired: boolean;
    authentication: {
      enabled: boolean;
      autoSignOut: boolean;
      oidcSettings: {
        authority: string;
        clientId: string;
        redirectUri: string;
        popupRedirectUri: string;
        responseType: string;
        scope: string;
        automaticSilentRenew: boolean;
        automaticSilentSignin: boolean;
        silentRedirectUri: string;
        postLogoutRedirectUri: string;
      };
    };
    api: {
      buyBCApiUrl: string,
      orgbookAPIUrl: string,
      buyBCIssueUrl: string,
      callbackUrl: string,
      orgbookOrgUrl: string
    };
    verify: {
      verifyUrl: string
    }
  }
  
  export class Configuration {
    public app!: AppConfig;
  }
  